import React from 'react';
import BaseInput from "../BaseInput";

export class Select extends BaseInput {

    render() {
        return (
            <select id={ this.props.name }
                    disabled={ this.props.disabled }
                    ref={ this.ref }
                    onChange={ this.onChange }
                    defaultValue={ this.props.value }
                    onBlur={ this.onChange }
                    className={ !this.state.valid ? 'invalid-input' : '' }>
                <option value={ '' }>{ this.props.placeholder }</option>
                { this.props.options.map(option => <option key={ option.key } value={ option.key }>{ option.value }</option>) }
            </select>
        );
    }
}

export default Select;
