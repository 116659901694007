import React from 'react';
import './radioInput.css';
import {BaseInput, DEFAULT_ERROR_MSG} from "../BaseInput";

export class RadioInput extends BaseInput {

    state = {
        value: null,
        valid: true
    }

    checkValid = value => {
        if (value != null || !this.props.required) {
            this.setState({valid: true});
            return [true, ''];
        } else {
            this.setState({valid: false});
            return [false, DEFAULT_ERROR_MSG];
        }
    }

    onChange = event => {
        const value = event.target.value;
        const [valid, msg] = this.checkValid(value);
        this.setState({valid, value});
        this.props.onChange(value, valid, msg);
    }

    setTouched = () => {
        const [valid, msg] = this.checkValid(this.state.value);
        this.props.onChange(this.state.value, valid, msg);
        if (!valid) {
            window.scrollTo(0, this.ref.current.offsetParent.offsetTop);
        }
    }

    componentWillMount() {
        this.setState({value: this.props.value})
    }

    render() {
        return (
            <div ref={this.ref}>
                {this.props.options
                    .map(option =>
                        <div className="custom-radio" key={option.label}>
                            <input
                                id={option.id}
                                defaultChecked={this.props.value ? (option.value.toString() === this.props.value) : null}
                                type="radio"
                                value={option.value}
                                name={this.props.name}
                                onChange={this.onChange}
                            />
                            <label htmlFor={option.id} className="radio-label">{option.label}</label>
                        </div>
                    )}
            </div>
        );
    }
}

export default RadioInput;
