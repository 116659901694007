import React from 'react';
import './fileInput.css';
import FileLabel from '../../FileLabel/FileLabel';
import { uploadAttachment } from '../../../services/complaintService'
import { BaseInput, DEFAULT_ERROR_MSG } from "../BaseInput";

const MAX_FILE_SIZE = 5 * 1024 * 1024;
const ACCEPTED_TYPES = ['text/plain', 'image/png', 'image/jpeg', 'application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'image/tiff', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/gif'];

export class FileInput extends BaseInput {

    state = {
        uploadedFiles: [],
        valid: false,
        validInput: true
    }

    filesChange = event => {
        if (!event) return;
        const file = event.target.files[0];
        if (ACCEPTED_TYPES.includes(file.type)) {
            const data = new FormData();
            if (file.size > MAX_FILE_SIZE) {
                const uploadedFiles = [...this.state.uploadedFiles, { valid: false, size: file.size, name: file.name, validationMessage: 'Załącznik ma nieprawidłowy rozmiar' }];
                this.setState({ uploadedFiles });
                this.props.onChange(uploadedFiles, ...this.checkValid(uploadedFiles));
            } else {
                data.append('file',  file);
                const size = Math.floor(file.size / 1024);
                this.save(data, { name: file.name, size });
            }
        } else {
            const uploadedFiles = [...this.state.uploadedFiles, { valid: false, size: file.size, name: file.name, validationMessage: 'Załącznik ma nieprawidłowy format' }];
            this.setState({ uploadedFiles });
            this.props.onChange(uploadedFiles, ...this.checkValid(uploadedFiles));
        }
        this.ref.current.value = "";
    }

    save = (formData, file) => {
        uploadAttachment(formData)
            .then(res => res.data)
            .then(data => {
                const uploadedFiles = [...this.state.uploadedFiles, {...data, size: file.size, valid: true}];
                this.setState({ uploadedFiles });
                this.props.onChange(this.state.uploadedFiles, ...this.checkValid(uploadedFiles));
            })
            .catch(err => {
                const uploadedFiles = [...this.state.uploadedFiles, { valid: false, size: file.size, name: file.name, validationMessage: 'Załącznik jest nieprawidłowy' }];
                this.setState({ uploadedFiles });
            })
    }

    deleteFile = id => {
        const uploadedFiles = [...this.state.uploadedFiles.filter(file => file.id !== id)];
        this.setState({ uploadedFiles: [...uploadedFiles] });
        this.props.onChange(uploadedFiles, ...this.checkValid(uploadedFiles));
    }

    onBlur = () => {
        const [valid, message] = this.checkValid(this.state.uploadedFiles);
        this.setState({ valid: !!this.ref.current.value || valid });
        this.props.onChange(this.state.uploadedFiles, !!this.ref.current.value || valid, message);
    }

    setTouched = () => {
        const [ valid, msg ] = this.checkValid(this.state.uploadedFiles);
        this.props.onChange(this.state.uploadedFiles, valid, msg);
        if (!valid) {
            window.scrollTo(0, this.ref.current.offsetParent.offsetParent.offsetTop);
        }
    }

    checkValid = files => {
        const validInput = !!files.length || !this.props.required;
        const valid = !files.filter(file => !file.valid).length && validInput;
        this.setState({ valid, validInput });
        return [valid, valid ? '' : DEFAULT_ERROR_MSG];
    }

    componentWillMount() {
        this.setState({ uploadedFiles: this.props.value || [] });
    }

    render() {
        return (
            <div>
                <div className={ 'dropbox' + (!this.state.validInput ? ' invalid-input' : '') }>
                    <input id={ this.props.name }
                           type="file"
                           name={ this.props.name }
                           ref={ this.ref }
                           onChange={ this.filesChange }
                           onBlur={ this.onBlur }
                           className="dropbox__input"
                           tabIndex="-1"
                    />
                    <p className="dropbox__instruction">Przeciągnij i upuść pliki na to pole albo załaduj z dysku</p>
                    <button className="dropbox__button">DODAJ PLIKI Z DYSKU</button>
                    <p className="dropbox__info">Dopuszczalne formaty:</p>
                    <p className="dropbox__info">.txt, .png, .jpg, .jpeg, .gif, .tiff, .pdf, .doc, .docx, .xls, .xlsx</p>
                    <p className="dropbox__info">Limit łącznego rozmiaru plików dla całego formularza: 5MB</p>
                </div>
                { this.state.uploadedFiles.map(file =>
                    <FileLabel key={ file.id } fileName={ file.name } valid={ file.valid } validationMessage={ file.validationMessage } id={ file.id } fileSize={ file.size } deleteFile={ this.deleteFile }></FileLabel>)
                }
            </div>);
    }
}

export default FileInput;
