import React, {Component} from 'react';
import CheckBox from "../inputs/CheckBox/CheckBox";
import PropTypes from "prop-types";
import './statements.css';

export class Statements extends Component {

    checkRefs = {
        contentOwner: React.createRef(),
        rulesOfServices: React.createRef(),
        facebookDataProcessing: React.createRef()
    }

    setTouched() {
        Object.values(this.checkRefs).reverse().forEach(ref => ref.current.setTouched());
    }

    render() {
        return (
            <form className='form'>
                <h4 className='subheader'>Oświadczenie składającego</h4>
                <label className='info-label'>Oświadczam, że:</label>
                { this.props.inputs.map(checkbox => (
                    <CheckBox key={ checkbox.name } required={ true } value={ checkbox.value } name={ checkbox.name } ref={ this.checkRefs[checkbox.name] }
                              onChange={ (value, valid) => this.props.onChange('statement', checkbox.name, value, valid) }
                              label={ checkbox.label }/>
                ))}
            </form>
        );
    }
}

Statements.propTypes = {
    onChange: PropTypes.func.isRequired,
    inputs: PropTypes.array
}

export default Statements;

