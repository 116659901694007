import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from "moment";

export const DEFAULT_ERROR_MSG = 'To pole jest obowiązkowe';
const REGEX_ERROR_MSG = 'Wartość nie pasuje do wzorca';
const CURRENT_DATE_ERROR_MSG = 'Data nie może być późniejsza niż aktualna';
const MAX_LENGTH_ERROR_MSG = 'Wartość nie może być dłuższa niż {count} znaków';

export class BaseInput extends Component {

    state = {
        valid: true
    }

    checkValid = value => {
        if (!value && this.props.required) {
            this.setState({ valid: false });
            return [false, DEFAULT_ERROR_MSG];
        }
        if (value) {
            if (this.props.regex && !new RegExp(this.props.regex).test(this.ref.current.value)) {
                this.setState({ valid: false });
                return [false, REGEX_ERROR_MSG];
            }
            if (this.props.maxlength && value.length > this.props.maxlength) {
                this.setState({ valid: false});
                return [false, MAX_LENGTH_ERROR_MSG.replace('{count}', this.props.maxlength)];
            }
            if (this.props.currentDate && moment(value, 'DD/MM/YYYY HH:mm').toDate() > new Date()) {
                this.setState({ valid: false});
                return [false, CURRENT_DATE_ERROR_MSG];
            }
        }
        this.setState({ valid: true, errorMsg: ''});
        return [true, ''];
    }

    setTouched = () => {
        const [ valid, msg ] = this.checkValid(this.ref.current.value);
        this.props.onChange(this.ref.current.value, valid, msg);
        if (!valid) {
            window.scrollTo(0, this.ref.current.offsetParent.offsetTop);
        }
    }


    onChange = () => {
        const [ valid, msg ] = this.checkValid(this.ref.current.value);
        this.props.onChange(this.ref.current.value, valid, msg);
    }

    ref = React.createRef();

}

BaseInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    regex: PropTypes.any,
    maxlength: PropTypes.number,
    currentDate: PropTypes.bool,
    options: PropTypes.array,
    onChange: PropTypes.func.isRequired
}

export default BaseInput;
