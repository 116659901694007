import React, {Component} from 'react';
import PropTypes from "prop-types";
import Input from "../inputs/Input";

export class Form extends Component {

    setTouched() {
        this.inputRefs.reverse().forEach(input => input.current.setTouched());
    }

    inputRefs = [];

    onChange = (property, value, valid) => {
        this.props.onChange(this.props.bean, property, value, valid);
    }

    componentWillMount() {
        this.props.inputs.forEach(() => this.inputRefs.push(React.createRef()));
    }

    render() {
        return (
            <form className="form">
                <h4 className='subheader'>{ this.props.label }</h4>
                { this.props.inputs
                    .map((input, index) =>
                    <Input
                        ref={ this.inputRefs[index] }
                        key={index}
                        name={input.name}
                        value={input.value}
                        type={input.type}
                        label={input.label}
                        regex={input.regex}
                        required={input.required}
                        placeholder={input.placeholder}
                        fullPrompt={input.fullPrompt}
                        currentDate={input.currentDate}
                        shortPrompt={input.shortPrompt}
                        promptDisabled={input.promptDisabled}
                        options={input.options}
                        maxlength={input.maxlength}
                        disabled={input.disabled}
                        onChange={ this.onChange }
                    />)
                }
            </form>
        );
    }
}

Form.propTypes = {
    bean: PropTypes.string,
    label: PropTypes.string.isRequired,
    inputs: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
}

export default Form;

