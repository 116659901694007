import React, {Component} from 'react';
import success from "../../assets/success.svg";
import './success.css';

export class Success extends Component {

    render() {
        return (
            <React.Fragment>
                <h1 className='header'>Dziękujemy za przesłanie wniosku</h1>
                <div className='success'>
                    <div><img className='success__icon' src={ success } alt='Sukces'></img></div>
                    <div>
                        <h4 className='subheader'>Sukces</h4>
                        <p className='success__desc'>Twój wniosek został pomyślnie dostarczony</p>
                    </div>
                </div>
                <a href='https://www.gov.pl'><button className='btn'>POWRÓT DO STRONY GŁÓWNEJ</button></a>
            </React.Fragment>
        );
    }
}

export default Success;
