import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './prompt.css';
import info from '../../assets/info-circle.svg';
import info_disabled from '../../assets/info-circle-disabled.svg';

export class Prompt extends Component {

    state = {
        expand: false
    }

    expandOrCollapse = () => this.setState({ expand: !this.state.expand });

    render() {
        return (
            <div className="prompt">
                <img className='prompt__icon' alt='info icon' src={ this.props.disabled ? info_disabled : info }/>
                { this.props.star ? <span className='prompt__star'>*</span> : '' }
                <span className={'prompt__label' + (this.props.disabled ? ' prompt__label--disabled' : '')}>
                    {(this.state.expand || this.props.disabled) ? this.props.full : this.props.short}
                </span>
                { this.props.disabled ? '' :
                    <span className="prompt__anchor" onClick={this.expandOrCollapse}>
                        <span className="info-toggler">{this.state.expand ? 'Pokaż mniej' : 'Pokaż całą instrukcję'}
                            <span className={'toggle-arrow-down' + (this.state.expand ? ' active' : '')}></span>
                        </span>
                    </span>
                }
             </div>
        );
    }
}

Prompt.propTypes = {
    full: PropTypes.string.isRequired,
    short: PropTypes.string,
    star: PropTypes.bool,
    disabled: PropTypes.bool
}

export default Prompt;
