import React, {Component} from 'react';
import Buttons from "../../components/Buttons/Buttons";
import CheckBox from "../../components/inputs/CheckBox/CheckBox";
import PropTypes from "prop-types";
import "./processing-clause.css";

export class ProcessingClause extends Component {

    state = {
        expandDesc: false,
        clauseAccepted: false
    }

    expandOrCollapse = () => this.setState({ expandDesc: !this.state.expandDesc });

    onClauseChange = (value, valid) => {
        if (value) {
            this.props.toggleRODOInfo(false);
        }
        this.setState({ clauseAccepted: value });
    }

    componentWillMount() {
        const clauseAccepted = this.state.clauseAccepted || this.props.clauseAccepted;
        this.setState( { clauseAccepted } );
    }

    goToGov = () => {
        window.location = 'https://www.gov.pl/';
    }

    goToForm = () => {
        if (this.state.clauseAccepted) {
            this.props.goToForm(this.state.clauseAccepted);
        } else {
            this.checkboxRef.current.setTouched(true);
            this.props.toggleRODOInfo(true);
            window.scrollTo(0, 0);
        }
    }

    checkboxRef = React.createRef();

    render() {
        return (
            <React.Fragment>
                <h1 className="header">Klauzula przetwarzania danych osobowych udostępnionych drogą elektroniczną</h1>
                <h4 className='clause__header'>Tożsamość administratora</h4>
                <label>Administratorem danych jest Minister Cyfryzacji, mający siedzibę w Warszawie (00-060) przy ul. Królewskiej 27, odpowiadający za zapewnienie funkcjonowania usługi "Odwołanie od decyzji portalu".</label>
                <h4 className='clause__header'>Dane kontaktowe administratora</h4>
                <p className='clause__desc--with-margin'>Z administratorem – Ministrem Cyfryzacji – można skontaktować się:</p>
                <ul className='clause__list'>
                    <li><span>pocztą elektroniczną pod adresem sekretariat.dc@mc.gov.pl,</span></li>
                    <li><span>pisemnie – kierując korespondencję na adres siedziby administratora.</span></li>
                </ul>
                <span className="anchor" onClick={ this.expandOrCollapse }>
                    <span className="info-toggler">{this.state.expandDesc ? 'zwiń' : 'pokaż całość'}
                        <span className={'toggle-arrow-down' + (this.state.expandDesc ? ' active' : '')}></span>
                    </span>
                </span>
                { this.state.expandDesc ?
                    <React.Fragment>
                        <h4 className='clause__header'>Dane kontaktowe inspektora ochrony danych</h4>
                        <p>Administrator – Minister Cyfryzacji – wyznaczył inspektora ochrony danych. Jest to osoba, z którą można się kontaktować we wszystkich sprawach dotyczących przetwarzania danych osobowych oraz korzystania z praw związanych z przetwarzaniem danych. Z inspektorem może Pan/Pani kontaktować się pocztą elektroniczną pod adresem iod@mc.gov.pl.</p>
                        <h4 className='clause__header'>Cele przetwarzania i podstawa prawna</h4>
                        <label>Pani/Pana dane osobowe podane w formularzu w zakresie: imię, nazwisko, adres e-mail, nazwa konta lub profilu, będą przetwarzane w celu obsługi zgłoszenia, którego dokonano za pośrednictwem usługi "Odwołanie od decyzji portalu". </label>
                        <h4 className='clause__header--small'>Podstawy prawne przetwarzania danych w celach określonych powyżej:</h4>
                        <ul className='clause__list clause__desc--with-margin'>
                            <li><span>art. 6 ust. 1 lit. a Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych).</span></li>
                        </ul>
                        <p className='clause__desc--with-margin'>Pani/Pana dane osobowe nie są przez administratora – Ministra Cyfryzacji - wykorzystywane na potrzeby marketingu bezpośredniego.</p>
                        <p className='clause__desc--with-margin'>Pani/Pana dane osobowe nie są przez administratora – Ministra Cyfryzacji - wykorzystywane do profilowania, ani innego podobnego podejmowania decyzji w sposób zautomatyzowany.</p>
                        <h4 className='clause__header'>Zakres danych osobowych, odbiorcy danych lub kategorie odbiorców danych</h4>
                        <p className='clause__desc--with-margin'>Pani/Pana dane osobowe są przekazywane NASK S.A. ul Wąwozowa 18, 02-796 Warszawa, jako podmiotowi przetwarzającemu, realizującemu na rzecz administratora danych zadania w zakresie utrzymania i rozwoju usługi "Odwołanie od decyzji portalu".</p>
                        <p className='clause__desc--with-margin'>Z NASK S.A. może Pan/Pani się skontaktować:</p>
                        <ul className='clause__list clause__desc--with-margin'>
                            <li><span>listownie na adres: Wąwozowa 18, 02-796 Warszawa</span></li>
                            <li><span>przez e-mail: kontakt@nasksa.pl;</span></li>
                            <li><span>telefonicznie: +48 22 182 00 00.</span></li>
                        </ul>
                        <p className='clause__desc--with-margin'>Dane osobowe zawarte w formularzu będą udostępnione portalowi Facebook w celu weryfikacji przez Facebook decyzji o zablokowaniu treści przez Facebook i będą przetwarzane zgodnie z postanowieniami regulaminu portalu Facebook, znajdującego się pod adresem: [https://pl-pl.facebook.com/legal/terms].</p>
                        <h4 className='clause__header'>Okres przechowywania danych</h4>
                        <p>Pani/Pana dane osobowe będą przetwarzane w okresie, który będzie wymagany w związku ze skorzystaniem z usługi "Odwołanie od decyzji portalu", nie dłużej jednak niż przez 90 dni od daty zamknięcia zgłoszenia. Po tym czasie dane osobowe zostaną usunięte z bazy administratora – Ministra Cyfryzacji.</p>
                        <h4 className='clause__header'>Prawa podmiotów danych</h4>
                        <p className='clause__desc--with-margin'>Przysługuje Pani/Panu prawo dostępu do treści swoich danych, ich sprostowania, usunięcia, prawo do przenoszenia danych oraz prawo do ograniczenia przetwarzania danych osobowych w przypadkach określonych w art. 18 RODO oraz prawo do cofnięcia udzielonej zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem. </p>
                        <p>Przysługuje Pani/Panu prawo do wniesienia sprzeciwu wobec przetwarzania moich danych osobowych, na podstawie art. 21 RODO, z przyczyn związanych z Pani/Pana szczególną sytuacją.</p>
                        <h4 className='clause__header'>Prawo wniesienia skargi do organu nadzorczego</h4>
                        <p className='clause__desc--with-margin'>Przysługuje Pani/Panu również prawo wniesienia skargi do organu nadzorczego Prezesa Urzędu Ochrony Danych Osobowych zajmującego się ochroną danych osobowych w państwie członkowskim Pani / Pana zwykłego pobytu, miejsca pracy lub miejsca popełnienia domniemanego naruszenia.</p>
                        <p>Prezes Urzędu Ochrony Danych Osobowych (PUODO)</p>
                        <p>ul. Stawki 2</p>
                        <p className='clause__desc--with-margin'>00-193 Warszawa.</p>
                        <a href='https://uodo.gov.pl/p/kontakt'>Szczegółowe informacje kontaktowe do UODO są dostępne pod tym linkiem.</a>
                        <h4 className='clause__header'>Informacja o dowolności lub obowiązku podania danych</h4>
                        <p>Podanie przez Panią/Pana danych osobowych jest dobrowolne ale niezbędne w celu skorzystania z usługi "Odwołanie od decyzji portalu". Niepodanie lub cofnięcie zgody na przetwarzanie tych danych w trakcie korzystania usługi będzie skutkowało niemożliwością jej realizacji.</p>
                    </React.Fragment>
                    : '' }
                <h4 className='clause__header'>Oświadczenie składającego</h4>
                <form className='form'>
                    <label className='clause__checkbox-label'>Oświadczam, że:</label>
                    <CheckBox required={ true } name={ 'contentOwner' } onChange={ this.onClauseChange } ref={ this.checkboxRef } value={ this.state.clauseAccepted }
                              label={ 'Wyrażam zgodę na przetwarzanie moich danych osobowych w celach realizacji usługi' }/>
                </form>
                <Buttons submitLabel='Dalej' back={ this.goToGov } submit={ this.goToForm }/>
            </React.Fragment>
        );
    }
}

export default ProcessingClause;

ProcessingClause.propTypes = {
    goToForm: PropTypes.func.isRequired,
    toggleRODOInfo: PropTypes.func.isRequired,
    clauseAccepted: PropTypes.any
}
