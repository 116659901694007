import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Prompt from "../Prompt/Prompt";
import TextBox from "./TextInput/TextBox";
import Select from "./Select/Select";
import FileInput from "./FileInput/FileInput";
import RadioInput from "./RadioInput/RadioInput";
import TextArea from "./TextArea/TextArea";

export class Input extends Component {

    state = {
        valid: true,
        errorMsg: ''
    }

    inputRef = React.createRef();

    setTouched = () => {
        this.inputRef.current.setTouched();
    }

    onChange = (value: any, valid: boolean, errorMsg: string) => {
        this.setState({ valid, errorMsg });
        this.props.onChange(this.props.name, value, valid)
    }

    switchInput (type) {
        switch (type) {
            case 'textbox':
                return <TextBox name={ this.props.name }
                                ref={ this.inputRef }
                                placeholder={ this.props.placeholder }
                                required={ this.props.required }
                                regex={ this.props.regex }
                                currentDate={ this.props.currentDate }
                                maxlength={ this.props.maxlength }
                                value={ this.props.value }
                                onChange={ this.onChange }
                />;
            case 'select':
                return <Select name={ this.props.name }
                               ref={ this.inputRef }
                               disabled={ this.props.disabled }
                               required={ this.props.required }
                               placeholder={ this.props.placeholder }
                               options={ this.props.options }
                               value={ this.props.value }
                               onChange={ this.onChange }
                />;
            case 'file':
                return <FileInput name={ this.props.name }
                                  ref={ this.inputRef }
                                  required={ this.props.required }
                                  value={ this.props.value }
                                  onChange={ this.onChange }
                />;
            case 'radio':
                return <RadioInput name={ this.props.name }
                                   ref={ this.inputRef }
                                   required={ this.props.required }
                                   options={ this.props.options }
                                   value={ this.props.value }
                                   onChange={ this.onChange }
                />;
            case 'textarea':
                return <TextArea name={ this.props.name }
                                 ref={ this.inputRef }
                                 required={ this.props.required }
                                 maxlength={ this.props.maxlength }
                                 value={ this.props.value }
                                 placeholder={ this.props.placeholder }
                                 onChange={ this.onChange }
                />;
            default:
        }
    }

    render() {
        return (
            <div className="form-group">
                <label htmlFor={ this.props.name }>{ this.props.label }
                    {this.props.required ? <span className='required-input-star'> *</span>: ''}
                </label>
                { this.props.fullPrompt ? <Prompt full={this.props.fullPrompt} short={this.props.shortPrompt} disabled={this.props.promptDisabled}/> : '' }
                { this.switchInput(this.props.type) }
                { !this.state.valid ? <div className="invalid-info">{ this.state.errorMsg }</div> : '' }
            </div>
        );
    }
}

Input.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    type: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    currentDate: PropTypes.bool,
    placeholder: PropTypes.string,
    regex: PropTypes.any,
    fullPrompt: PropTypes.string,
    shortPrompt: PropTypes.string,
    promptDisabled: PropTypes.bool,
    options: PropTypes.array,
    maxlength: PropTypes.number,
    onChange: PropTypes.func.isRequired
}

export default Input;
