import React from 'react';
import './checkBox.css';
import BaseInput, {DEFAULT_ERROR_MSG} from "../BaseInput";

export class CheckBox extends BaseInput {

    state = {
        value: null,
        valid: true
    }

    checkValid = value => {
        if (value || !this.props.required) {
            this.setState( { valid: true} );
            return [true, ''];
        } else {
            this.setState( { valid: false} );
            return [false, DEFAULT_ERROR_MSG];
        }
    }

    onChange = event => {
        const value = event.target.checked;
        const [ valid, msg ] = this.checkValid(value);
        this.setState({ valid, value });
        this.props.onChange(value, valid, msg);
    }

    setTouched = withoutScroll => {
        const [ valid, msg ] = this.checkValid(this.state.value);
        this.props.onChange(this.state.value, valid, msg);
        if (!valid && !withoutScroll) {
            window.scrollTo(0, this.ref.current.offsetParent.offsetTop);
        }
    }

    componentWillMount() {
        this.setState({ value: this.props.value })
    }

    render() {
        return (
            <div className="custom-checkbox">
                <input id={ this.props.name } ref={ this.ref } defaultChecked={ this.props.value } name={ this.props.name } onChange={ this.onChange } type="checkbox"/>
                <label htmlFor={ this.props.name } className={ !this.state.valid ? 'invalid' : ''}>{ this.props.label }
                    {this.props.required ? <span className='required-input-star'> *</span>: ''}
                </label>
            </div>
        );
    }
}

export default CheckBox;
