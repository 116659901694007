import React, {Component} from 'react';
import './contact-point.css';
import Complaint from "./routes/Complaint/Complaint";
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import ProcessingClause from "./routes/ProcessingClause/ProcessingClause";
import { saveComplaint } from './services/complaintService';
import Success from "./routes/Success/Success";
import Messenger from "./components/Messenger/Messenger";

export class ContactPoint extends Component {

    state = {
        complaint: {},
        clause: false,
        path: window.location.hash.substring(1),
        loading: false,
    }

    sendComplaint = complaint => {
        saveComplaint(complaint).then(() => this.setState({ path: '/success' }));
    }

    backToClause = complaint => {
        this.setState({ complaint, path: '/' });
        window.scrollTo(0, 0);
    }

    goToForm = clause => {
        this.setState({ clause, path: '/form' });
        window.scrollTo(0, 0);
    }

    messengerRef = React.createRef();

    render() {
        return (
            <Router basename='/app/pk/#'>
                <div className="contact-point">
                    <div className="contact-point-content">
                        <Messenger ref={ this.messengerRef }/>
                        <React.Fragment>
                            <Redirect to={ this.state.path } />
                            <Route exact path="/" component={ () => <ProcessingClause clauseAccepted={ this.state.clause } goToForm={ this.goToForm }
                                  toggleRODOInfo={ show => this.messengerRef.current.toggleRODOInfo(show) } />}/>
                            <Route path="/form" component={ () => <Complaint complaint={ this.state.complaint } back={ this.backToClause } showServerError={ () => this.messengerRef.current.showServerError() }
                                 showValidationInfo={ (show, result) => this.messengerRef.current.showValidationInfo(show, result) } save={ this.sendComplaint } />} />
                            <Route path="/success" component={ () => <Success/>}/>
                        </React.Fragment>
                    </div>
                </div>
            </Router>
        );
    }
}

export default ContactPoint;
