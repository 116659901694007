const config = {};

const getData = () => {
    fetch('config.json',{
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            } })
        .then(response => response.json())
        .then(data => {
            config.BACK_URL = data.BACK_URL;
            config.AUTH_URL = data.AUTH_URL;
        });
}

getData();
export default config;
