import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './fileLabel.css';
import uploaded from "../../assets/file-uploaded.png"
import CloseIcon from '../../assets/close.svg';

export class FileLabel extends Component {

    render() {
        return (
            <React.Fragment>
                <div className={ 'file-info-container' + (this.props.valid ? '' : ' invalid') }>
                    <div className="file-info-container-image">
                        <img src={ uploaded } alt="Plik został załadowany"/>
                    </div>
                    <div className="file-info-container-data">
                        <div className="file-name-container">
                            <div>{ this.props.fileName }</div>
                            <div className="file-remove-button" tabIndex="0">
                                <img src={ CloseIcon } alt='Zamknij plik' onClick={ () => this.props.deleteFile(this.props.id) }/>
                            </div>
                        </div>
                        <div className="file-details-container">
                            <div>{ this.props.fileSize } kb</div>
                            <div>100%</div>
                        </div>
                    </div>
                </div>
                <div className='validation-message'>{ this.props.validationMessage }</div>
            </React.Fragment>
            );
    }
}

FileLabel.propTypes = {
    id: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    fileSize: PropTypes.number.isRequired,
    valid: PropTypes.bool.isRequired,
    validationMessage: PropTypes.string,
    deleteFile: PropTypes.func.isRequired
}

export default FileLabel;
