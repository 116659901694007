import React, {Component} from 'react';
import PropTypes from 'prop-types';

export class Preloader extends Component {

    render() {
        return this.props.show ?  (
            <div role="alert" aria-live="assertive" className="preloader vertical-center">
                <div className="spinner">
                    <div className="spinner-inner">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        ) : "";
    }
}

Preloader.propTypes = {
    show: PropTypes.bool
}

export default Preloader;
