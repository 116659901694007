import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Prompt from "../../components/Prompt/Prompt";
import Form from "../../components/Form/Form";
import Statements from "../../components/Statements/Statements";
import Buttons from "../../components/Buttons/Buttons";
import attachmentInputs from "../../inputs/attachments"
import accountInputs from "../../inputs/accountData"
import complaintInputs from "../../inputs/complaintData"
import statements from "../../inputs/statementData"
import additionalInfoInputs from "../../inputs/additionalInfo"
import config from "../../config";
import { checkLogged } from '../../services/complaintService';
import {Preloader} from "../../components/Preloader/Preloader";
import "./complaints.css";

export class Complaint extends Component {

    _isMounted = false;

    forms = {
        accountData: React.createRef(),
        complaintData: React.createRef(),
        attachments: React.createRef(),
        additionalInfo: React.createRef(),
        statement: React.createRef()
    }

    state = {
        touched: false,
        loading: true,
        serverFailed: false,
        complaint: { accountData: {}, data: {}, attachments: {}, additionalInfo: {}, statement: {
                contentOwner: { value: false },
                rulesOfServices: { value: false },
                facebookDataProcessing: { value: false }
            }}
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.checkLogged();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChange = (bean, property, value, valid) => {
        const complaint = this.state.complaint;
        complaint[bean][property] =  { value, valid };
        this.setState({ complaint });
        if (this.state.touched) {
            this.props.showValidationInfo(false, this.validate());
        }
    }

    convertFields = (data, inputs) => {
        const v = inputs.map(input => {
            return { input: input, object: Object.entries(data).filter(([key, value]) => input.name === key)[0][1] };
        });
        return v.filter(a => a.object.valid === false)
            .map(a => a.input.errorLabel)
            .join(', ');
    }

    validate = () => {
        const accountDataResult = { name: 'Dane identyfikacyjne', fields: this.convertFields(this.state.complaint.accountData, accountInputs) };
        const complaintResult = { name: 'Informacje dotyczące skargi', fields: this.convertFields(this.state.complaint.data, complaintInputs) };
        const attachmentResult = { name: 'Załączniki', fields: this.convertFields(this.state.complaint.attachments, attachmentInputs) };
        const additionalInfo = { name: 'Dodatkowe informacje', fields: this.convertFields(this.state.complaint.additionalInfo, additionalInfoInputs) };
        attachmentResult.fields = attachmentResult.fields ? `${attachmentResult.fields}, ${this.validateAttachmentsSize()}` : this.validateAttachmentsSize();
        const statementResult = { name: 'Oświadczenie składającego', fields: this.convertFields(this.state.complaint.statement, statements) };
        return [accountDataResult, complaintResult, attachmentResult, additionalInfo, statementResult];
    }

    validateAttachmentsSize = () => {
        const size = (this.state.complaint.additionalInfo.deletedPhotoFiles.value.map(value => value.size).reduce((prev, actual) => prev + actual, 0) || 0) +
            (this.state.complaint.attachments.emails.value.map(value => value.size).reduce((prev, actual) => prev + actual, 0) || 0);
        const valid = size <= 5 * 1024;
        return valid ? '' : 'Rozmiar wszystkich załączników nie może przekroczyć 5MB';
    }

    backToClause = () => {
        this.props.back(this.state.complaint);
    }

    checkLogged = () => {
        if (!config.BACK_URL) {
            setTimeout(this.checkLogged, 200);
            return;
        }
        checkLogged()
            .then(() => {
                if (this._isMounted) {
                    this.setState({loading: false});
                }
            })
            .catch( err => {
            this.loading = true;
            if (err?.response?.status === 401 || err?.response?.status === 403) {
                window.location = config.AUTH_URL;
            } else {
                this.setState({ serverFailed: true, loading: false });
                this.props.showServerError();
            }
        });
    }

    save = () => {
        this.setState({ touched: true });
        Object.values(this.forms).reverse().forEach(form => form.current.setTouched());
        const validateResult = this.validate();
        if (!validateResult.filter(result => !!result.fields).length) {
            this.props.save(this.state.complaint);
        } else {
            this.props.showValidationInfo(true, validateResult);
        }
    }

    componentWillMount() {
        const complaint = { ...this.state.complaint, ...this.props.complaint };
        this.setState( { complaint } );
        const allInputs = [...accountInputs, ...complaintInputs, ...attachmentInputs, ...additionalInfoInputs, ...statements];
        Object.entries(this.props.complaint).forEach(([key, group]) => {
            Object.entries(group).forEach(([beanKey, bean]) => {
                allInputs.filter(input => input.name === beanKey)[0].value = bean.value;
            });
        });
    }

    render() {
        return (<React.Fragment>
                <Preloader show={this.state.loading}/>
                { (!this.state.loading && !this.state.serverFailed) ?
                    <React.Fragment>
                        <h1 className="header">Odwołanie od decyzji portalu społecznościowego</h1>
                        <section className='account-data-prompt'>
                            <p className='account-data-prompt__header'>UWAGA!</p>
                            <p>Niniejszy formularz ma na celu pomóc użytkownikowi sprawdzić decyzje dotyczące naruszeń Standardów Społeczności. Formularz nie służy do wysyłania próśb o rozpatrzenie innych spraw.</p>
                            <div className='account-data-prompt__list'>
                                Uprzejmie informujemy, że poniższe wnioski nie będą rozpatrywane za pomocą tego formularza:
                                <ul>
                                    <li><span>Problemy z dostępem do konta: na przykład zapomniane hasło, problem z weryfikacja za pomocą SMS lub dokumentów</span></li>
                                    <li><span>Zhakowane konta</span></li>
                                    <li><span>Ograniczenia lub blokady związane z produktem reklamowym</span></li>
                                </ul>
                            </div>
                            Informujemy, że Facebook nie będzie w stanie rozpatrzyć wniosku, jeśli adres e-mail nie jest powiązany kontem, o którym mowa. Nie możemy udzielać informacji o koncie osobom trzecim.
                        </section>
                        <Prompt full='Pola obowiązkowe' disabled={ true } star={ true } />
                        <Form bean='accountData' ref={ this.forms.accountData } label='Dane identyfikacyjne' inputs={ accountInputs } onChange={ this.onChange }/>
                        <Form bean='data' label='Informacje dotyczące skargi' ref={ this.forms.complaintData } inputs={ complaintInputs } onChange={ this.onChange }/>
                        <Form bean='attachments' label='Załączniki' ref={ this.forms.attachments } inputs={ attachmentInputs } onChange={ this.onChange }/>
                        <Form bean='additionalInfo' label='Dodatkowe informacje' ref={ this.forms.additionalInfo } inputs={ additionalInfoInputs } onChange={ this.onChange }/>
                        <Statements inputs={ statements } ref={ this.forms.statement } onChange={ this.onChange }/>
                        <Buttons back={ this.backToClause } submit={ this.save } submitLabel='Wyślij'/>
                    </React.Fragment> : ''
                }
            </React.Fragment>
        );
    }
}

Complaint.propTypes = {
    back: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    showValidationInfo: PropTypes.func.isRequired,
    showServerError: PropTypes.func.isRequired,
    complaint: PropTypes.any
}

export default Complaint;
