import axios from "axios";
import moment from "moment";
import config from "../config";

export const uploadAttachment = (data) => {
    return axios.post(`${config.BACK_URL}/attachments`, data);
}

export const checkLogged = () => {
    return axios.get(`${config.BACK_URL}/check`);
}

export const logout = () => {
    return axios.get(`${config.BACK_URL}/logout`);
}

export const saveComplaint = data => {
    const complaint = parseComplaint(data);
    return axios.post(`${config.BACK_URL}/complaints`, complaint);
}

const parseComplaint = complaint => {
    const accountData = {};
    Object.keys(complaint.accountData).forEach(key => accountData[key] = complaint.accountData[key].value);
    const statement = {};
    Object.keys(complaint.statement).forEach(key => statement[key] = complaint.statement[key].value);
    const data = {};
    Object.keys(complaint.attachments).forEach(key => data[key] = complaint.attachments[key].value);
    Object.keys(complaint.data).forEach(key => data[key] = complaint.data[key].value);
    Object.keys(complaint.additionalInfo).forEach(key => data[key] = complaint.additionalInfo[key].value);
    let deletedDate: Date = '';
    if (data.deletedDate) {
        deletedDate = moment(data.deletedDate, 'DD/MM/YYYY HH:mm').toDate();
        deletedDate.setTime( deletedDate.getTime() - deletedDate.getTimezoneOffset() * 60 * 1000 );
    }
    return {...data, accountData, statement, attachments: [...(data.emails || []), ...(data.deletedPhotoFiles || [])], emails: null, deletedPhotoFiles: null, deletedDate };
}

