import React from 'react';
import './textArea.css';
import BaseInput from "../BaseInput";

export class TextArea extends BaseInput {

    state = {
        leftChars: this.props.maxlength,
        limitExceeded: false,
        submitted: false,
        focused: false,
        valid: true
    }

    onChange = () => {
        const [ valid, msg ] = this.checkValid(this.ref.current.value);
        this.setState({leftChars: this.props.maxlength - this.ref.current.value.length,
                            limitExceeded: (this.props.maxlength === this.ref.current.value.length),
                            valid});
        this.props.onChange(this.ref.current.value, valid, msg);
    }

    onBlur = () => {
        this.onChange();
        this.setState({ focused: false });
    }

    render() {
        return (
            <React.Fragment>
                <textarea rows="8"
                          name={ this.props.name }
                          ref={ this.ref }
                          onChange={ this.onChange }
                          defaultValue={ this.props.value }
                          maxLength={ this.props.maxlength }
                          placeholder={ this.props.placeholder }
                          onBlur={ this.onBlur }
                          onFocus={ () => this.setState({ focused: true }) }
                          className={ !this.state.valid ? 'invalid-input' : '' }
                />
                { this.state.focused ?
                    <div className={ 'letter-left-info' + (this.state.limitExceeded ? ' letter-left-info--exceeded' : '')}>
                        Pozostało { this.state.leftChars } znaków
                    </div>
                : '' }
            </React.Fragment>
        );
    }
}

export default TextArea;
