import React, {Component} from 'react';
import './messenger.css';
import CloseIcon from '../../assets/close.svg';
import AlertIcon from '../../assets/incorrect.svg';

const RODO_TITLE = 'Informacja o dowolności lub obowiązku podania danych';
const RODO_DESC = 'Informacja o dowolności lub obowiązku podania danych\n' +
    'Pani/Pana dane są niezbędne, aby zrealizować wybraną przez Panią/Pana usługę ' +
    'cyfrową udostępnioną w serwisie moj.gov.pl lub umożliwić administratorowi prowadzenie komunikacji z Panią/Panem. ' +
    'Niepodanie tych danych będzie skutkowało niemożliwością korzystania z tych usług.';

const SERVER_ERROR_TITLE = 'Błąd serwera!';
const SERVER_ERROR_DESC = 'Aplikacja nie odpowiada, spróbuj później.';

export class Messenger extends Component {

    state = {
        show: false,
        errors: [],
    }

    toggleRODOInfo = show => {
        this.setState( { errors: [{ title: RODO_TITLE, description: RODO_DESC }], show });
    }

    showServerError = () => {
        this.setState( { errors: [{ title: SERVER_ERROR_TITLE, description: SERVER_ERROR_DESC }], show: true });
    }

    showValidationInfo = ( show, groups) => {
        const errors = [...groups.filter(group => !!group.fields).map(group => ({ title: group.name, description: group.fields }))];
        this.setState({ show: !!errors.length && ( this.state.show || show ), errors: [...groups.filter(group => !!group.fields).map(group => ({ title: group.name, description: group.fields }))] });
    }

    close = () => {
        this.setState({ show: false });
    }

    render() {
        return (
            <div className={'messenger' + (this.state.show ? '' : ' hide')}>
                <div className="messenger__close-button" tabIndex="0">
                    <img className="messenger__close-img" src={ CloseIcon } alt='Zamknij' onClick={ this.close }/>
                </div>
                { this.state.errors.map(error =>
                    <div className="messenger__error" key={ error.title }>
                        <div className="messenger__error__icon"><img className="messenger__error__img" src={ AlertIcon } alt='Błąd'/></div>
                        <div>
                            <p className="messenger__error__title">{ error.title }</p>
                            <p className="messenger__error__desc">{ error.description }</p>
                        </div>
                    </div>
                ) }
            </div>
        );
    }
}

export default Messenger;

