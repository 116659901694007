import React from 'react';
import BaseInput from "../BaseInput";

export class TextBox extends BaseInput {

    render() {
        return (
            <React.Fragment>
                <input type="text"
                       ref={ this.ref }
                       id={ this.props.name }
                       defaultValue={ this.props.value }
                       placeholder={ this.props.placeholder }
                       onChange={ this.onChange }
                       onBlur={ this.onChange }
                       className={ !this.state.valid ? 'invalid-input' : '' }
                />
            </React.Fragment>
        );
    }
}

export default TextBox;
