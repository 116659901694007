import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './buttons.css';

export class Buttons extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="buttons">
                    <button onClick={ this.props.back } className="btn btn-secondary">ANULUJ</button>
                    <button onClick={ this.props.submit } className="btn btn-primary">{ this.props.submitLabel }</button>
                </div>
                <div className="clear-both"></div>
            </React.Fragment>

        );
    }
}

Buttons.propTypes = {
    submitLabel: PropTypes.string.isRequired,
    submit: PropTypes.func,
    back: PropTypes.func
}

export default Buttons;
